<template>
  <div
    :style="{ 'box-shadow': 'rgba(0, 0, 0, 0.15) 0px 4px 10px 4px' }"
    @contextmenu="this.setContextIdWidget"
  >
    <div
      class="block tool_group"
      :style="{
        top: this.mouse_y - 25 + 'px',
        left: this.mouse_x + 'px',
      }"
      :id="'tooltip' + this.widgetId"
    >
      <div v-if="isPlanTooltip" class="actc_tool text-white">
        <p :key="i" v-for="(msg, i) of planTooltipValues.split('</br>')">
          {{msg }}
        </p>
      </div>
    </div>
    <div
      class="flex justify-between"
      :style="{
        width: widgetWidth + 'px',
        'background-color':
          backgroundColor ||
          (this.darkDark !== 'white' ? 'var(--widgetBg)' : 'var(--widgetBg)'),
        transition: 'var(--transition)',
      }"
    >
      <button
        :style="{
          color:
            this.textColor || (this.darkDark !== 'white' ? 'white' : 'black'),
        }"
      >
        INCL :
        {{
          this.rosebudData["incl"] && this.rosebudData["incl"][6]
            ? parseFloat(this.rosebudData["incl"][6]).toFixed(1)
            : "0"
        }}
        &deg;
      </button>
      <button
        :style="{
          color:
            this.textColor || (this.darkDark !== 'white' ? 'white' : 'black'),
        }"
      >
        AZI :
        {{
          this.rosebudData["azi"] && this.rosebudData["azi"][6]
            ? parseFloat(this.rosebudData["azi"][6]).toFixed(1)
            : "0"
        }}
        &deg;
      </button>
    </div>
    <div
      :id="`chartdiv${widgetId}`"
      :style="{
        // height: (widgetHeight + 30) + 'px',
        width: widgetWidth + 'px',
        'background-color':
          backgroundColor ||
          (this.darkDark !== 'white' ? 'var(--widgetBg)' : 'var(--widgetBg)'),
        transition: 'var(--transition)',
        display: 'flex',
        'justify-content': 'center',
      }"
    >
      <span class="hidden">{{ darkDark }}</span>
    </div>
    <div
      class="flex justify-between"
      :style="{
        width: widgetWidth + 'px',
        'background-color':
          backgroundColor ||
          (this.darkDark !== 'white' ? 'var(--widgetBg)' : 'var(--widgetBg)'),
        transition: 'var(--transition)',
      }"
    >
      <button
        :style="{
          color:
            this.textColor || (this.darkDark !== 'white' ? 'white' : 'black'),
        }"
      >
        TVD :
        {{
          this.rosebudData["tvd"] && this.rosebudData["tvd"][6]
            ? parseFloat(this.rosebudData["tvd"][6]).toFixed(1)
            : "0"
        }}
        (ft)
      </button>
      <button
        :style="{
          color:
            this.textColor || (this.darkDark !== 'white' ? 'white' : 'black'),
        }"
        v-on:click="toggleMtfGtf"
      >
        {{ activated.toUpperCase() }} :
        {{
          this.rosebudData[this.activated] &&
          this.rosebudData[this.activated][6]
            ? parseFloat(this.rosebudData[this.activated][6]).toFixed(1)
            : "0"
        }}
        &deg;
      </button>
      <button
        :style="{
          color:
            this.textColor || (this.darkDark !== 'white' ? 'white' : 'black'),
        }"
      >
        MD :
        {{
          this.rosebudData["md"] && this.rosebudData["md"][6]
            ? parseFloat(this.rosebudData["md"][6]).toFixed(1)
            : "0"
        }}
        (ft)
      </button>
    </div>
  </div>
</template>

<script>
import api from "../../../api/services";
import { select, range, scaleLinear, symbol, symbolDiamond } from "d3";
export default {
  name: "RosebudWidget",
  components: {},
  props: {
    widgetId: String,
    displayId: String,
    openConfig: Function,
    radarWidgetConfig: Object,
    widgetHeight: {
      type: [Number, String],
      default: 200,
    },
    widgetWidth: {
      type: [Number, String],
      default: 200,
    },
    textColor: String,
    backgroundColor: String,
    directionalID: {
      type: Number,
      default: 1,
    },
    radarStart: {
      type: Number,
      default: 300,
    },
    radarEnd: {
      type: Number,
      default: 400,
    },
    rangeStart: {
      type: Number,
      default: 0,
    },
    rangeEnd: {
      type: Number,
      default: 360,
    },
    showGrid: {
      type: Boolean,
      default: false,
    },
    verticalcardBG: {
      default: "#3f3f3f",
    },
    lightThemeDarkColor: {
      default: "black",
    },
    logTypes: Object,
    isRadarVisible: Boolean,
    numberOfDataPoints: {
      default: 7,
      type: [Number, String],
    },
    log_type: {
      type: String,
      default: "trajectory",
    },
  },

  data() {
    return {
      activated: "gtf",
      color: ["#FFFFFF", "#FFFFFF", "#FFFFFF"],
      rosebudData: {},
      logTypesData: {
        gtf: [
          // { x: 320, y: 1.0 },
          // { x: 320, y: 2 },
          { x: 320, y: 5.0 },
          { x: 320, y: 8 },
          { x: 320, y: 13.0 },
          { x: 320, y: 16 },
          { x: 320, y: 12 },
        ],
        mtf: [{ x: 180, y: 8.0 }],
      },
      logTypesDataBackup: {},
      centerLabel: "GTF \n 320",
      legendLabel: "GTF - 320",
      radians: 0.0174532925,
      rosebudRadius: this.widgetHeight / 2,
      margin: 50,
      width: "",
      height: "",
      hourHandLength: "",
      minuteHandLength: "",
      secondHandLength: "",
      secondHandBalance: "",
      secondTickStart: "",
      secondTickLength: "",
      hourTickStart: "",
      hourTickLength: "",
      secondLabelRadius: "",
      secondLabelYOffset: "",
      hourLabelRadius: "",
      hourLabelYOffset: "",
      mouse_x: 0,
      mouse_y: 0,
      isPlanTooltip: false,
      planTooltipValues: "",
      realtimeTimeOut: "",
      showDataAvailable: {},
    };
  },
  computed: {
    darkDark() {
      setTimeout(() => {
        this.drawRosebud();
      }, 300);

      return this.$store.state.rect.darkmode;
    },
  },
  methods: {
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.widgetId,
        contextType: "widget",
      });
    },
    drawRosebud() {
      let textColor =
        this.textColor || (this.darkDark !== "white" ? "white" : "black");
      console.log(
        "rosebud text color : ",
        this.widgetId,
        this.darkDark,
        textColor
      );
      let backgroundColor =
        this.backgroundColor || (this.darkDark !== "white" ? "black" : "white");
      let numberOfPoints = this.numberOfDataPoints;

      this.rosebudRadius = (this.widgetHeight - 120) / 2;
      this.margin = 37;
      this.width = (this.rosebudRadius + this.margin) * 2;
      this.height = (this.rosebudRadius + this.margin) * 2;
      this.hourHandLength = (2 * this.rosebudRadius) / 3;
      this.minuteHandLength = this.rosebudRadius;
      this.secondHandLength = this.rosebudRadius - 12;
      this.secondHandBalance = 30;
      this.secondTickStart = this.rosebudRadius;
      this.secondTickLength = -10;
      this.hourTickStart = this.rosebudRadius;
      this.hourTickLength = -12;
      this.secondLabelRadius = this.rosebudRadius + 16;
      this.secondLabelYOffset = 5;
      this.hourLabelRadius = this.rosebudRadius + 22;
      this.hourLabelYOffset = 7;

      var hourScale = scaleLinear().range([0, 330]).domain([0, 330]);

      var secondScale = scaleLinear().range([0, 354]).domain([0, 59]);
      var svg = select(`#chartdiv${this.widgetId}`).selectAll("*").remove();
      svg = "";
      svg = select(`#chartdiv${this.widgetId}`)
        .append("svg")
        .attr("width", this.width)
        .attr("height", this.height);

      var face = svg
        .append("g")
        .attr("id", "clock-face")
        .attr(
          "transform",
          "translate(" +
            (this.rosebudRadius + this.margin) +
            "," +
            (this.rosebudRadius + this.margin) +
            ")"
        );

      //add marks for seconds
      face
        .selectAll(".second-tick")
        .data(range(0, 60))
        .enter()
        .append("line")
        .attr("class", "second-tick")
        .attr("x1", 0)
        .attr("x2", 0)
        .attr("y1", this.secondTickStart)
        .attr("y2", this.secondTickStart + this.secondTickLength)
        .attr("transform", function (d) {
          return "rotate(" + secondScale(d) + ")";
        })
        .attr("stroke", textColor);

      face
        .selectAll(".hour-tick")
        .data(range(0, 360, 30))
        .enter()
        .append("line")
        .attr("class", "hour-tick")
        .attr("x1", 0)
        .attr("x2", 0)
        .attr("y1", this.hourTickStart)
        .attr("y2", this.hourTickStart + this.hourTickLength)
        .attr("transform", function (d) {
          return "rotate(" + hourScale(d) + ")";
        })
        .attr("stroke-width", 4)
        .attr("stroke", textColor);

      let radians = this.radians;
      let hourLabelRadius = this.hourLabelRadius;
      let hourLabelYOffset = this.hourLabelYOffset;
      let active = this.activated;
      face
        .selectAll(".hour-label")
        .data(range(0, 360, 30))
        .enter()
        .append("text")
        .attr("class", "hour-label")
        .attr("text-anchor", "middle")
        .attr("x", function (d) {
          return hourLabelRadius * Math.sin(hourScale(d) * radians);
        })
        .attr("y", function (d) {
          return (
            -hourLabelRadius * Math.cos(hourScale(d) * radians) +
            hourLabelYOffset
          );
        })
        .text(function (d, i) {
          if (active == "gtf" && d > 180) {
            return d - 360;
          } else return d;
        })
        .attr("fill", textColor);

      var hands = face.append("g").attr("id", "clock-hands");

      let realTimeTooltip = (event, data, isShow, index) => {
        this.mouse_x = event.clientX;
        this.mouse_y = event.clientY;
        let text = `
        MD : ${
          this.showDataAvailable["md"] && this.showDataAvailable["md"][index]
            ? parseFloat(this.showDataAvailable["md"][index]).toFixed(2)
            : "0"
        }ft
        </br>
        INCL : ${
          this.showDataAvailable["incl"] &&
          this.showDataAvailable["incl"][index]
            ? parseFloat(this.showDataAvailable["incl"][index]).toFixed(2)
            : "0"
        }°
      </br>
      AZI : ${
        this.showDataAvailable["azi"] && this.showDataAvailable["azi"][index]
          ? parseFloat(this.showDataAvailable["azi"][index]).toFixed(2)
          : "0"
      }°
      </br>
        TVD : ${
          this.showDataAvailable["tvd"] && this.showDataAvailable["tvd"][index]
            ? parseFloat(this.showDataAvailable["tvd"][index]).toFixed(2)
            : "0"
        }ft
      </br>
      ${this.activated.toUpperCase()} : ${data.toFixed(2)}°`;

        this.planTooltipValues = text;

        this.isPlanTooltip = isShow;
      };
      let hideRealTimeTooltip = (event, text, isShow) => {
        if (this.tooltipTimeOut) {
          clearTimeout(this.tooltipTimeOut);
        }
        this.tooltipTimeOut = setTimeout(() => {
          this.mouse_x = 0;
          this.mouse_y = 0;
          this.planTooltipValues = "";
          this.isPlanTooltip = isShow;
        }, 300);
      };

      let showData = [];
      this.showDataAvailable = { ...this.rosebudData };
      if (numberOfPoints < 0 || numberOfPoints >= 7) {
        showData = this.rosebudData[active];

        if (numberOfPoints >= 7) numberOfPoints = 7;
        if (numberOfPoints < 0) numberOfPoints < 1;
      } else {
        showData = this.rosebudData[active].slice(7 - numberOfPoints, 7);
        for (let i in this.rosebudData) {
          this.showDataAvailable[i] = this.rosebudData[i].slice(
            7 - numberOfPoints,
            7
          );
        }
        console.log(
          "🚀 ➽ file: Rosebud.vue:386  ➽ drawRosebud  ➽ showData ⏩",
          showData
        );
      }

      let diamondShape = symbol().type(symbolDiamond).size(30);
      let rangeCustom = range(
        20,
        this.rosebudRadius,
        (this.rosebudRadius - 20) / numberOfPoints
      );

      if (rangeCustom && rangeCustom.length && rangeCustom.length > 7) {
        rangeCustom.pop();
      }
      if (active == "mtf" && showData && showData.length)
        hands
          .append("g")
          .selectAll("dot")
          .data(rangeCustom)
          .enter()
          .append("path")
          .attr("d", diamondShape)
          .attr("transform", function (d, index) {
            let angle = 0;
            if (showData[index])
              angle =
                showData[index] < 0 ? -1 * showData[index] : showData[index];
            let yValue = d * Math.sin(hourScale(angle) * radians);
            let xValue = -d * Math.cos(hourScale(angle) * radians);

            return "translate(" + yValue + ", " + xValue + ")";
          })
          .style("opacity", 1)
          .attr("fill", function (d, index) {
            if (index < showData.length) {
              if (index == showData.length - 1)
                if (active == "mtf") return "red";
                else return "red";
              else return textColor;
            } else {
              return backgroundColor;
            }
          })
          .on("mouseover", function (e) {
            let index = rangeCustom.indexOf(select(this).data()[0]);

            if (index < showData.length) {
              let angle = 0;
              if (showData[index]) {
                angle = showData[index];
                realTimeTooltip(e, angle, true, index);
              } else realTimeTooltip(e, 0, true, index);
            }
          })
          .on("mousemove", function (e) {
            let index = rangeCustom.indexOf(select(this).data()[0]);

            if (index < 7) {
              let angle = 0;
              if (showData[index]) {
                angle = showData[index];
                realTimeTooltip(e, angle, true, index);
              } else {
                realTimeTooltip(e, angle, true, index);
              }
            }
          })
          .on("mouseout", function (e) {
            hideRealTimeTooltip(e, "", false);
          });

      if (active == "gtf" && showData && showData.length)
        hands
          .selectAll("line")
          .data(rangeCustom)
          .enter()
          .append("circle")
          .attr("cx", function (d, index) {
            if (index < 7) {
              let angle = 0;
              if (showData[index])
                // showData[index] < 0 ? -1 * showData[index] :
                angle = showData[index];
              return d * Math.sin(hourScale(angle) * radians);
            }
          })
          .attr("cy", function (d, index) {
            if (index < 7) {
              let angle = 0;
              if (showData[index]) angle = showData[index];
              return -d * Math.cos(hourScale(angle) * radians);
            }
          })
          .attr("r", 3)
          .attr("stroke", function (d, index) {
            if (index < showData.lengt) {
              if (index == showData.lengt - 1)
                if (active == "mtf") return "red";
                else return "red";
              else return textColor;
            } else {
              return backgroundColor;
            }
          })
          .attr("fill", function (d, index) {
            if (index < showData.length) {
              if (index == showData.length - 1)
                if (active == "mtf") return "red";
                else return "red";
              else return textColor;
            } else {
              return backgroundColor;
            }
          })
          .on("mouseover", function (e) {
            let index = rangeCustom.indexOf(select(this).data()[0]);
            // console.log("circle hover : ", showData);
            if (index < 7) {
              let angle = 0;
              if (showData[index]) {
                angle = showData[index];
                realTimeTooltip(e, angle, true, index);
              } else realTimeTooltip(e, 0, true, index);
            }
          })
          .on("mousemove", function (e) {
            let index = rangeCustom.indexOf(select(this).data()[0]);

            if (index < 7) {
              let angle = 0;
              if (showData[index]) {
                angle = showData[index];
                realTimeTooltip(e, angle, true, index);
              } else realTimeTooltip(e, 0, true, index);
            }
          })
          .on("mouseout", function (e) {
            hideRealTimeTooltip(e, "", false);
          });
    },
    toggleMtfGtf() {
      if (this.activated == "gtf") this.activated = "mtf";
      else this.activated = "gtf";
      this.drawRosebud();
    },
    async loadDirectionalWidget(dtls) {
      console.log("exicuting::::::::");
      let newVal = { data: { gtf: [], mtf: [] } };
      let epocTime = new Date(this.max).getTime();
      try {
        newVal = await api.WellServices.getDirectionForTrajectory({
          well_id: this.wellId,
          wellbore_name: this.wellboreId,
          max: this.max,
          log_type: this.log_type,
        });

        this.rosebudData = newVal.data;

        this.showDataAvailable = { ...this.rosebudData };
        this.turnOffSocket();
        if (this.log_type == "time") this.getRealtimeData();
        console.log(
          "🚀 ➽ file: Rosebud.vue:536  ➽ loadDirectionalWidget  ➽ newVal.data ⏩",
          this.rosebudData
        );
        this.drawRosebud();
      } catch (error) {
        console.error("No data present for Rosebud : ", error.message);
        return false;
      }

      return true;
    },
    getRealtimeData(title) {
      let topic = this.topic;
      let fields = ["mtf", "gtf", "azimuth", "inclination", "tvd", "dmea"];

      this.fields = fields;
      let topicExists = this.$store.state.live.isSubscribed[topic];

      // if (this.logId) {
      this.$socket.emit("subscribe", {
        logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.log_type}`,
        topic: topic,
        fields,
        id: this.widgetId,
        clientId: this.$clientId,
        logType: this.log_type,
      });
      if (!topicExists) {
        this.$store.dispatch("live/setSubscriptionFlag", {
          topic,
          flag: true,
        });
      }

      this.sockets.subscribe(this.$clientId, (sResult) => {
        let result = sResult[this.widgetId];
        let key = "";
        let final = [];

        if (result) {
          for (let i in this.rosebudData) {
            if (i == "md") {
              final = result["dmea"];
              console.log("fields : ", i, result["dmea"]);
            } else if (i == "incl") {
              final = result["inclination"];
              console.log("fields : ", i, result["inclination"]);
            } else if (i == "azi") {
              final = result["azimuth"];
              console.log("fields : ", i, result["azimuth"]);
            } else {
              final = result[i];
              console.log("fields : ", i, result[i]);
            }

            if (final.length > 6) {
              this.rosebudData[i] = final.slice(-7, final.length);
            } else {
              this.rosebudData[i].splice(0, final.length);
              this.rosebudData[i] = [...this.rosebudData[i], ...final];
            }
          }
          this.drawRosebud();
        }
      });

      topicExists = this.$store.state.live.isSubscribed[topic];
    },
    dark() {
      this.$emit("dark");
    },
    light() {
      this.$emit("light");
    },
    turnOffSocket() {
      try {
        this.$socket.emit("unsubscribe", {
          logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~time`,
          topic: this.topic,
          id: this.widgetId,
          clientId: this.$clientId,
        });
      } catch (error) {
        console.error("disconnect unsubscribe error ", error);
      }
    },
  },
  watch: {
    numberOfDataPoints(newValue) {
      this.drawRosebud();
    },
    widgetHeight(newValue) {
      this.drawRosebud();
    },
    widgetWidget(newValue) {
      this.drawRosebud();
    },
    textColor: async function (newVal, oldVal) {
      this.drawRosebud();
    },
    backgroundColor: async function (newVal, oldVal) {
      this.drawRosebud();
    },
    rangeStart: async function (newVal, oldVal) {
      this.drawRosebud();
    },
    rangeEnd: async function (newVal, oldVal) {
      this.drawRosebud();
    },
    radarStart: async function (newVal, oldVal) {
      this.drawRosebud();
    },
    radarEnd: async function (newVal, oldVal) {
      this.drawRosebud();
    },
    showGrid: async function (newVal, oldVal) {
      this.drawRosebud();
    },
    log_type: async function (newVal, oldVal) {
      console.log("logtype::::::::", newVal);
      if (newVal == "time" && this.notTimeAvailable) {
        console.log("data not available1");
        await this.loadDirectionalWidget({});
      } else {
        console.log("data not available2");
        await this.loadDirectionalWidget({});
      }
    },
  },
  async mounted() {
    let displayId = this.displayId;
    let displayDtls = this.$store.state.disp.displays[displayId];
    if (displayDtls) {
      this.wellId = displayDtls.wellId;
      this.wellboreId = displayDtls.wellboreId;
      this.logId = displayDtls.logId;
      this.wellboreState = displayDtls.wellboreState;
      this.logType = displayDtls.logType;
      this.table = displayDtls.table;
      this.topic = displayDtls.topicName;
      try {
        console.log(
          "welll details for max : ",
          this.$store.state.data.wells[this.wellId]
        );
        this.max =
          this.$store.state.data.wells[this.wellId]["wellbores"][
            this.wellboreId
          ]["logs"]["time"].endTime;
      } catch (error) {
        console.log("error in set max", error);
        this.notTimeAvailable = true;
      }
      this.status = displayDtls.status;
      console.log("display details : ", displayDtls);
      // clearInterval(this.loadTableInterval);
      // if (this.status == "UPDATING")
      //   this.loadTableInterval = setInterval(async () => {
      //     await this.loadDirectionalWidget(displayDtls);
      //   }, 60000);
      await this.loadDirectionalWidget(displayDtls);
    }
    this.$store.subscribe(async (mutation, state) => {
      switch (mutation.type) {
        case "disp/setDisplay":
          if (mutation.payload.display == this.displayId) {
            this.wellId = mutation.payload.wellId;
            this.wellboreId = mutation.payload.wellboreId;
            this.logId = mutation.payload.logId;
            this.wellboreState = mutation.payload.wellboreState;
            this.logType = mutation.payload.logType;
            this.topic = mutation.payload.topicName;
            this.table = mutation.payload.table;
            this.max = mutation.payload.max;
            this.status = mutation.payload.status;
            try {
              this.max =
                this.$store.state.data.wells[this.wellId]["wellbores"][
                  this.wellboreId
                ]["logs"]["time"].endTime;
            } catch (error) {
              console.log("error in set max m", error);
              this.notTimeAvailable = true;
            }

            // clearInterval(this.loadTableInterval);
            // if (this.status == "UPDATING")
            //   this.loadTableInterval = setInterval(async () => {
            //     await this.loadDirectionalWidget(mutation.payload);
            //   }, 60000);
            await this.loadDirectionalWidget(mutation.payload);
          }
          break;
      }
    });
  },
  sockets: {
    connect: function () {},
    disconnect() {
      try {
        this.$socket.emit("unsubscribe", {
          logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.log_type}`,
          topic: this.topic,
          id: this.widgetId,
          clientId: this.$clientId,
        });
      } catch (error) {
        console.error("disconnect unsubscribe error ", error);
      }
    },

    reconnect() {
      this.$socket.emit("subscribe", {
        logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.log_type}`,
      });
    },
  },
};
</script>

<style scoped>
svg {
  stroke: #000;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
}

#rim {
  fill: none;
  stroke: #999;
  stroke-width: 3px;
}

.second-hand {
  stroke-width: 3;
}

.minute-hand {
  stroke-width: 8;
  stroke-linecap: round;
}

.hour-hand {
  stroke-width: 12;
  stroke-linecap: round;
}

.hands-cover {
  stroke-width: 3;
  fill: #fff;
}

.second-tick {
  stroke-width: 3;
  fill: #000;
}

.hour-tick {
  stroke-width: 8;
}

.second-label {
  font-size: 12px;
}

.hour-label {
  font-size: 24px;
}

.tool_group {
  position: fixed;
  display: inline-grid;
  /* left: 221px; */
  right: 162px;
  z-index: 99999;
}

.actc_tool {
  font-size: 10px;
  background: #000000;
  width: auto;
  position: absolute;
  padding: 2px 5px;
  /* border-radius: 20px; */
}
</style>
